@import '../../../scss/mixins';
@import '../../../scss/colors';
@import '../../../globals/typography/typography.scss';

.featurette-section {
  position: relative;
  overflow: hidden;
  padding: 3.75rem 0;
  .content-wrapper {
    margin: 0;
    .col-lg-5 {
      @media only screen and (max-width: 1200px) and (min-width: 992px) {
        width: 100%;
      }
    }
    .col-lg-7 {
      @media only screen and (max-width: 1200px) and (min-width: 992px) {
        width: 100%;
      }
    }
  }
  .FeaturetteChart-col-lg-5 {
    padding: 0;
  }
  .FeaturetteChart-col-lg-7 {
    //padding: 0 0 0 2.375rem;
    @include mobile {
      padding: 0;
    }
    &.left {
      padding-right: 2.375rem;
      @include mobile {
        padding-top: 2.375rem 0 0 0;
      }
    }
    &.right {
      padding-left: 2.375rem;
      @include mobile {
        padding-left: 0;
        padding-bottom: 2.375rem;
      }
    }
  }
  .featurette-data {
    canvas {
      opacity: 70%;
    }
  }
  @include respond-between(md, xl) {
    padding: 3.75rem 5rem;
  }
  @include respond-above(xl) {
    padding: 3.75rem 5rem;
  }
  .banner-image {
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    object-fit: cover;
  }
  .featurette-body {
    background-color: $ts-background-primary;

    &.left-padding {
      @include respond-above(sm) {
        padding-left: 110px;
      }
    }

    .sub-title {
      @include label(medium);
      margin: 0 0 0.75rem 0;
      color: $ts-background-on-primary-2;
      line-height: 22px;
    }
    .title {
      @include headline(4);

      margin: 0 0 0.75rem 0;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 42px; /* 118.75% */
      letter-spacing: -1px;
      @include respond-below(lg) {
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px; /* 121.429% */
        letter-spacing: -0.5px;
      }
    }

    .richtext-wrapper > div {
      font-family: $font-family-2;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      color: $color-footergray;
    }
    .content-wrapper {
      margin: 0;
      color: $ts-background-on-primary-2;
    }
    .cta-wrapper {
      margin: 2rem 0 0 0;
    }
  }
  .featurette-img {
    height: auto;
    width: auto;
    overflow: hidden;
    max-width: 100%;
    .video__link{
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .play_icon{
        position: absolute;
        width: 80px;
        height: 80px;
      }
    }

    img {
      position: relative;
      display: block;
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      border-radius: 0.75rem;
      aspect-ratio: 1;
    }
  }
  &.standard {
    @include respond-below(lg) {
      display: flex;
      padding: 3.75rem 1.25rem;
      // min-height: 50rem;
    }
    @include respond-below(md) {
      padding: 3.75rem 1.25rem;
    }

    @include respond-above(md) {
      padding: 3.75rem 5rem;
    }

    .content-wrapper {
      align-items: center;
      @include respond-below(lg) {
        gap: 2rem;
      }
      &.left {
        flex-direction: row-reverse;
        @include respond-below(sm) {
          justify-content: flex-start;
          align-items: flex-start;
          // height: 100%;
        }
        .featurette-img {
          // margin-left: 15%;
          border-radius: 12px;

          @include respond-below(lg) {
            margin: 0;
          }
          @include respond-below(sm) {
            padding: 0 0 2rem 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            margin: 0 auto;
          }
          @include respond-above(sm) {
            height: auto;
            width: auto;
            overflow: hidden;
            max-width: 100%;
          }
        }
      }
      &.right {
        @include respond-below(sm) {
          flex-direction: column-reverse;
          justify-content: flex-end;
          align-items: flex-end;
          height: 100%;
        }
        .featurette-img {
          // margin-right: 15%;
          @include respond-below(lg) {
            margin: 0;
          }
          @include respond-below(sm) {
            padding: 2rem 0 0 0;
          }
        }
      }
    }
    &.white {
      .content-wrapper {
        &.right {
          @include respond-below(sm) {
            flex-direction: column;
          }
        }
      }
    }
    &.featurettePromoIntro{
      &.dark{
        background-color: $black;
        color:$white
      }
      &.light{
        color: $black;
      }
      .featurettePromoIntro-wrapper{
        padding: 0;
        h3.titleFont{
          font-size: 1.5rem;
          line-height: 1.75rem;
          margin:0;
        }
      }
      &.featuretteSourcesDisclosures{
        @include respond-above(md){
        padding: 1.5rem 5rem;
      }
      @include respond-below(md){
        padding: 1.5rem;
      }
      h3.title{
        font-size: 1.25rem;
        line-height: 1.5rem;
        font-weight: 600;
        margin-bottom:0.5rem;
      }
      .sub-title div {
        font-size: 0.75rem;
      }
      }
    }
    
  }
  &.bg-image {
    padding: 1.25rem;
    @include respond-between(md, xl) {
      padding: 5rem 5rem;
    }
    @include respond-above(xl) {
      padding: 5rem;
      height: 65vh;
    }
    @include respond-below(md) {
      height: 812px;
    }
    .featurette-body {
      border-radius: 0.75rem;

      @include respond-below(sm) {
        padding: 1.75rem;
      }

      @include respond-above(sm) {
        padding: 3rem;
      }
      .title {
        @include headline(4);
      }
    }
    &.primary {
      .featurette-body {
        background-color: $ts-background-primary;
      }
    }
    &.secondary {
      .featurette-body {
        color: $ts-background-on-secondary-1;
        background-color: $ts-background-secondary;
        .title,
        .sub-title {
          color: $ts-background-on-secondary-1;
        }
      }
    }
    .content-wrapper {
      &.right {
        @include respond-above(md) {
          flex-direction: row-reverse;
        }
      }
      &.left {
        flex-direction: column-reverse;
        justify-content: flex-end;
        align-items: flex-end;
        height: 100%;
        @include respond-above(sm) {
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          height: unset;
        }
      }
    }
  }
  &.insight {
    @include respond-below(sm) {
      min-height: unset;
      flex-direction: column;
      padding: 0 0 3rem 0;
      &.right {
        flex-direction: column-reverse;
        padding: 3rem 0 0;
        .banner-image {
          margin: 1.75rem 0 0;
        }
      }
      .featurette-body {
        padding: 0;
        border-radius: 0;
      }
      .banner-image {
        position: static;
        display: block;
        height: 18rem;
        margin: 0 0 1.75rem 0;
      }
    }
  }
  &.promo {
    &.primary {
      background-color: $ts-background-primary;
      .featurette-body {
        color: $ts-background-on-primary-1;
      }
    }
    &.secondary {
      background-color: $ts-background-secondary;
      .featurette-body {
        color: $ts-background-on-secondary-1;
      }
    }
    &.violet {
      background-color: $ts-accent-1-primary;
      .featurette-body {
        color: $ts-accent-1-on-accent-1-primary;
      }
    }
    .content-wrapper {
      align-items: center;
      .richtext-wrapper {
        margin: 0;
      }
      .cta-wrapper {
        margin-top: 2rem;
      }
      &.right {
        flex-direction: row-reverse;
        .featurette-img {
          img {
            @include respond-above(md) {
              margin: 0 0 0 auto;
            }
          }
        }
      }
      .featurette-img {
        margin: 0 0 2rem 0;
        @include respond-above(md) {
          margin: 0;
        }
        img {
          max-width: 80%;
          margin: 0 auto;
          @include respond-above(md) {
            margin: 0 auto 0 0;
          }
        }
      }
    }
  }
  &.video {
    .video__link {
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url('../../../static/icons/icon--play-button.svg');
        background-size: cover;
        background-repeat: no-repeat;
        width: 6rem;
        height: 6rem;
        filter: brightness(0) invert(1);
        opacity: 1;
        transition: $btn-transition;
      }
      &:hover {
        &:after {
          opacity: 0.75;
        }
      }
    }
  }
  &.value-cards {
    min-height: unset;
  }
}
.featurette-section.bg-image.primary .featurette-body {
  background-color: #fff;
}

.featurette-body-standard {
  @include respond-above(md) {
    &.left {
      // padding-right: 122px;
    }
    &.right {
      // padding-left: 122px;
    }
  }
  .title {
    margin-bottom: 0.75rem;
    @include respond-below(lg) {
      line-height: 34px; /* 121.429% */
    }
  }
  .sub-title {
    margin-bottom: 0.75rem;
  }
}
.cta-wrapper-button {
  padding-top: 2rem;
}
