@import './../../../../../../scss/colors';
@import './../../../../../../scss/mixins';

.subNavComponent {
  &:not(.mobileNav) {
    &.insights {
      .subNavComponent {
        &__inner {
          max-width: calc(1280px + 2rem);

          @include respond-below(md) {
            display: grid;
            grid-template-columns: 1fr;
          }
        }

        &__left {
          justify-content: center;
          @media (992px <= width <= 1250px) {
            min-width: 315px;
          }
        }
        &__right {
          padding-left: 1rem;
          @media (992px <= width <= 1250px) {
            width: 70%;
          }
        }

        &__list {
          grid-template-columns: repeat(4, 1fr);
          width: 100%;
          @include respond-between(md, lg) {
            grid-template-columns: repeat(2, 1fr);
            padding-left: 2rem;
          }
        }

        &__title {
          @media (992px <= width <= 1250px) {
            br {
              display: none;
            }
          }
        }

        &__cta {
          justify-content: space-between;
          width: 100%;
        }

        &__right {
          max-width: 864px;
        }

        .subNavComponent__contact_cta {
          padding-top: 9px;
          padding-bottom: 9px;
          line-height: 1;
        }
      }

      .link_card {
        position: relative;
        isolation: isolate;
        min-height: 270px;
        @include respond-between(md, lg) {
          min-height: 125px;
        }
        img {
          opacity: 0.5;
        }
        &:hover {
          img {
            transform: scale(1.2);
            opacity: 0.75;
          }
        }

        .card-image {
          position: absolute;
          inset: 0;
          z-index: -1;
          background-color: $ts-color-black;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            transition: all 0.15s ease-in-out;
          }
        }

        .card-body {
          display: flex;
          flex-direction: column;
          justify-content: stretch;
          width: 100%;
          height: 100%;
          padding-top: 2.75rem;
          padding-bottom: 1.5rem;
          padding-left: 1.5rem;
          padding-right: 1.5rem;
          @include respond-between(md, lg) {
            padding: 1.5rem;
          }

          h3 {
            @include headline(5);
            font-size: 1.75rem;
            text-transform: none;
            letter-spacing: -0.031rem;
            color: white;

            @media screen and (min-width: 992px) and (max-width: 1440px) {
              font-size: 1.375rem;
              font-size: clamp(1.375rem, 0.5446428571428572rem + 1.3392857142857142vw, 1.75rem);
            }
          }

          .button {
            width: fit-content;
            margin-top: auto;
            font-size: 1.25rem;
          }
        }
      }
    }
  }

  .subNavComponent {
    &__cta {
      button.secondary-button.signpost {
        .arrow-icon {
          width: 1.75rem;
          height: 1.75rem;
          background-size: 1.75rem;
        }
        &:hover,
        &:focus {
          .arrow-icon {
            width: 2.5rem;
            height: 2.5rem;
            background-size: 2.5rem;
          }
        }
      }
    }
  }
}
